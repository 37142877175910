import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import Countdown from 'react-countdown';
import { FETCH_ABHYAS_USER_QUESTION_PAPER, UPDATE_ABHYAS_USER_QUESTION_PAPER } from '../../../../app/service/abhyas-module-questions.service';
import TimerInfo from '../../../dashboard/mns/timer/timerinfo';
import { UPDATE_PARIKSHA_USER_QUESTION_PAPER } from '../../../../app/service/pariksha-module-questions';
import useUnsavedChangesWarnMessage from '../../../../app/utility/unSavedChangesWarnMsg';


export const AbhyasUserQuestionsList = () => {
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [startTime, setStartTime] = useState(new Date());
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    // const [test_start_date, setTestStartDate] = useState<any>(getTimeDetails());
    let { moduleUUID, userCode, schoolCode, subjectUuid } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string, subjectUuid: string }>();
    const history = useHistory();
    const [minutes, setMinutes] = useState(60);
    const [seconds, setSeconds] = useState(0);
    let completedTime: string = "";
    let time_remain_storage = '';
    let timeUp = false;
    const [isActive, setIsActive] = useState<boolean>(false);
    let selectUuids = JSON.parse(localStorage.getItem('userSelectedSubjectInfo') || '{}');
    const questionRefs = useRef<(HTMLInputElement | null)[]>([]);
    const backworKey = localStorage.getItem("backword_key");
    const type = localStorage.getItem("abhyas_type");
    const testInfo: any = JSON.parse(localStorage.getItem("abhyas_test_info") || '{}');
    const cycleNumber = localStorage.getItem("abhyas_cycle_no");
    const worksheets: any = JSON.parse(localStorage.getItem('abhyas_worksheets') || '{}');
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [imageSize, setImageSize] = useState<number>(0);
    const [showQuestions, setShowQuestions] = useState<boolean>(false);
    const [attemptQuestionCount, setAttemptQuestionCount] = useState<number>(0);
    const warnMassage: string = 'Are you sure you want to leave ?';
    const [Prompt, setDirty, setPristine] = useUnsavedChangesWarnMessage(warnMassage);
    const audioRef = useRef<HTMLAudioElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (false) {
            const hasDefaultMethodBeenCalled = localStorage.getItem('hasDefaultMethodBeenCalled');

            if (!hasDefaultMethodBeenCalled) {
                localStorage.setItem('hasDefaultMethodBeenCalled', JSON.stringify(true));
            } else {

                refreshCalled();
            }

            return () => {
                localStorage.removeItem('hasDefaultMethodBeenCalled');
            };
        }
    }, []);
    useEffect(() => {
        if (backworKey === "true") {
            history.push('/student-dashboard/list')
        }
        getAbhyaasQuestionsList(testInfo, type, cycleNumber);

        // if (type === 'TESTS') {
        setDirty();
        // }

    }, []);
    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (isActive) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isActive]);


    function refreshCalled() {
        getAbhyaasQuestionsList(testInfo, type, cycleNumber);
        // getAbhyaasInfo()
        // getAbhyaasInfo()
        setTimeout(() => {
            handleCompleteSubmit();
        }, 2000);

    }

    function getTime(time: any) {
        if (time !== 'null') {
            return time;
        } else {
            return time ? time : startTime;
        }
    }


    function getAbhyaasQuestionsList(testInfo: any, type: any, cycleNumber: any) {
        let payload = {
            "type": type,
            "uuid": testInfo?.question_paper_uuid
        }
        setShowHeader(false);
        setLoading(true);
        FETCH_ABHYAS_USER_QUESTION_PAPER(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setLoading(false);
                } else {
                    if (res) {
                        if (res) {
                            // if (res.hasOwnProperty("data") ) {
                            if (Array.isArray(res)) {
                                localStorage.setItem("questionsData", JSON.stringify(res));
                                setQuestionsData(res);
                                setShowHeader(true);
                                setStartTimer(true);
                                if (type === 'WORKSHEETS') {
                                    setIsActive(true);
                                }
                                // setLoading(false);
                                setLoading(false);
                            } else {
                                toast.error("Questions not found");
                                setLoading(false);

                            }

                        } else {
                            // setQuestionsData([]);
                            setShowHeader(true);
                            setStartTimer(true);
                            setLoading(false);
                        }

                    }

                }
            }
        });
    }



    function onViewQuestion(name: any) {
        return { __html: name };
    }


    function handleQuestionOptions(item: any, index: number, j: number) {
        const data = questionsData;
        if (data?.length == currentQuestion + 1) {
            data[index].checked = true;
        }
        for (let i = 0; i < alphabets.length; i++) {
            if (j === i) {
                data[index].answer = alphabets[i];
            }
        }
        setQuestionsData([...data]);
        localStorage.setItem("questionsData", JSON.stringify(data));
    }


    const handleCompleteSubmit = () => {
        if (testInfo?.module_code === "PARIKSHA") {
            const payload = {
                "user_code": userCode,
                "school_code": schoolCode,
                "subject_uuid": subjectUuid,
                "type": type,
                "question_paper_uuid": testInfo?.question_paper_uuid,
                "time_taken": type === "TESTS" ? (calculateTimeDifference() ? calculateTimeDifference() : localStorage.getItem("setTime")) : (getTimeTaken(seconds) ? getTimeTaken(seconds) : localStorage.getItem("setTime")),
                "questions": questionsData.length > 0 ? questionsData : JSON.parse(localStorage.getItem("questionsData") || '{}'),
                "test_time": `${minutes}.0`,
                "test_uuid": testInfo?.test_uuid,
                "topic_uuid": testInfo?.topic_uuid,
                "worksheet_uuid": testInfo?.worksheet_uuid,
            }

            setLoading(true);
            UPDATE_PARIKSHA_USER_QUESTION_PAPER(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setLoading(false);
                    } else {
                        setPristine();
                        localStorage.setItem("backword_key", "true");
                        setLoading(false);
                        const data = res;
                        if (type === 'WORKSHEETS') {
                            toast.success("You have successfully Submitted worksheet");
                            handleclose();
                            return;
                        }
                    }
                }
            });
        } else {

            const payload = {
                "user_code": userCode,
                "school_code": schoolCode,
                "subject_uuid": subjectUuid,
                "cycle_no": cycleNumber ? parseInt(cycleNumber) : 1,
                "type": type,
                "test_no": testInfo && testInfo.test_no ? testInfo.test_no : 1,
                "question_paper_uuid": type === 'TESTS' ? (testInfo?.question_paper_uuid ? testInfo?.question_paper_uuid : '') : (testInfo?.question_paper_uuid ? testInfo?.question_paper_uuid : ''),
                "question_paper_level": testInfo.level ? testInfo.level : '',
                "priority_no": testInfo?.priority ? testInfo?.priority : '',
                "time_taken": type === "TESTS" ? (calculateTimeDifference() ? calculateTimeDifference() : localStorage.getItem("setTime")) : (getTimeTaken(seconds) ? getTimeTaken(seconds) : localStorage.getItem("setTime")),
                "questions": questionsData.length > 0 ? questionsData : JSON.parse(localStorage.getItem("questionsData") || '{}'),
                "test_time": `${minutes}.0`,
            }
            if (type === "WORKSHEETS") {
                let addPayLoad = {
                    "topic_uuid": testInfo?.topic_uuid,
                    "worksheet_uuid": testInfo?.worksheet_uuid,
                }
                Object.assign(payload, { ...addPayLoad })
            }

            setLoading(true);
            UPDATE_ABHYAS_USER_QUESTION_PAPER(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                        toast.error(errorValue);
                        setLoading(false);
                    } else {
                        setPristine();
                        localStorage.setItem("backword_key", "true");
                        setLoading(false);
                        const data = res;
                        if (type === 'WORKSHEETS') {
                            toast.success("You have successfully Submitted worksheet");
                            handleclose();
                            // setCanShowPopup(true);
                            return;
                        }
                        history.push(`/student-dashboard/list/abhyas/success-page/${moduleUUID}/${userCode}/${schoolCode}/${testInfo.test_no}`);
                    }

                }
            });
        }

    }

    const getCircleColor = (item: any) => {
        if (item?.status === "pending") {
            if (item?.worksheet_no === testInfo?.worksheet_no) {
                return '#FCEE21';
            } else {
                return '#FFFFFF';
            }
        } else if (item?.status === 'completed') {
            if (item?.result === "fail") {
                return '#f15a24';
            } else {
                return '#8CC63F';
            }
        } else {
            return '#FFFFFF';
        }
    }


    const handleclose = () => {
        setCanShowPopup(false);
        let prepredData = { ...selectUuids };
        history.push(`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${prepredData?.school_code}/${prepredData?.board_uuid}/${prepredData?.medium_uuid}/${prepredData?.standard_uuid}/${prepredData?.subject_uuid}`)
    }


    // Renderer callback with condition
    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: {
        days: any;
        hours: any;
        minutes: any;
        seconds: any;
        completed: any;
    }) => {
        if (completed) {
            return <div></div>
            // Render a completed state
        } else {
            // Render a countdown
            return (
                <span style={{ fontSize: "1rem", paddingTop: "10px" }}>
                    {hours} Hr - {minutes} Min - {seconds} Sec
                </span>
            );
        }
    };
    const calculateTimeDifference = () => {
        const initialTime = `${"59"}:${"59"}`
        const [initialMinutes, initialSeconds] = initialTime.split(':').map(Number);
        const [finalMinutes, finalSeconds] = completedTime.split(':').map(Number);
        const initialTimeInSeconds = Number(initialMinutes) * 60 + Number(initialSeconds);
        const finalTimeInSeconds = Number(finalMinutes) * 60 + finalSeconds ? Number(finalSeconds) : 0;
        const differenceInSeconds = Number(initialTimeInSeconds * 100) - Number(finalTimeInSeconds * 100);

        if (differenceInSeconds >= 60) {
            var mins = moment.utc(moment(initialTime, "mm:ss").diff(moment(completedTime, "mm:ss"))).format("mm.ss")
            localStorage.setItem("setTime", mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0'));
            return mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0')
        } else {
            localStorage.setItem("setTime", `0.${differenceInSeconds}`);
            return `0.${differenceInSeconds}`;
        }

    };
    const getTimeTaken = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        let totalTime = `${minutes.toString().padStart(2, '0')}.${seconds.toString().padStart(2, '0')}`
        let finalTime = totalTime?.replace('00', '0')
        localStorage.setItem("setTime", `${Number(finalTime)}`);

        return Number(finalTime);
    }
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent page reload
            const nextIndex = currentIndex + 1;
            if (nextIndex < questionRefs.current.length) {
                questionRefs.current[nextIndex]?.focus();
            }
        }
    };

    const getDomElements = (htmlString: any, tag: string) => {
        const parsedContent = new DOMParser().parseFromString(htmlString, 'text/html');
        const pElements = parsedContent.querySelectorAll('p');
        const imgElement: any = parsedContent.querySelector('img');
        if (tag === "p") {
            const paragraphs: any[] = [];
            pElements.forEach((pElement) => {
                paragraphs.push(`<p class="mb-0">${pElement.innerHTML}</p>`);
                // paragraphs.push(<p className='mb-0'>{pElement.innerHTML}</p>);
            });
            return <div dangerouslySetInnerHTML={{ __html: paragraphs?.join('') }} />

            // return paragraphs?.join('');
            // return <p>{pElement ? pElement.textContent : ""}</p>
        }
        if (tag === "img") {
            if (imgElement) {
                return imgElement ? imgElement.getAttribute('src') : ''
            } else {
                return "";
            }

        }
        return "";
    }
    const handlePrevious = () => {
        const data = questionsData;
        if (!data[currentQuestion].checked) {
            data[currentQuestion].answer = "";
            data[currentQuestion].checked = false;
        }
        localStorage.setItem("questionsData", JSON.stringify(data));
        setQuestionsData([...data]);
        getImageDimensions(data[currentQuestion - 1].question);
        setCurrentQuestion((prev) => prev - 1);
    };
    const handleClearSelection = () => {
        setCurrentQuestion(currentQuestion);
        const data = questionsData;
        data[currentQuestion].answer = "";
        data[currentQuestion].checked = false;
        localStorage.setItem("questionsData", JSON.stringify(data));
        setQuestionsData([...data]);
    };
    const handleNext = () => {
        const data = questionsData;
        if (data[currentQuestion].answer) {
            data[currentQuestion].checked = true;
        }
        localStorage.setItem("questionsData", JSON.stringify(data));
        setQuestionsData([...data]);
        getImageDimensions(data[currentQuestion + 1].question);
        setCurrentQuestion((prev) => prev + 1);
    };
    const selectQuestion = (questioNo: any) => {
        let preElement = parseInt(questioNo);
        const data = questionsData;
        if (data[currentQuestion].answer && (data[currentQuestion]?.id === questionsData[questionsData.length - 1]?.id)) {
            data[currentQuestion].checked = true;
        } else if (!data[currentQuestion].checked) {
            data[currentQuestion].answer = "";
            data[currentQuestion].checked = false;
        }
        getImageDimensions(data[preElement].question);
        setCurrentQuestion(preElement);
    }
    const getImageDimensions = (question: any) => {
        const parsedContent = new DOMParser().parseFromString(question, 'text/html');
        const imgElement: any = parsedContent.querySelector('img');
        if (imgElement) {
            const img = new Image();
            img.src = imgElement.getAttribute('src');
            img.onload = () => {
                setImageSize(img.height);
            };
        }
    };
    const getLevelMedium = (level: string) => {
        switch (level) {
            case "LOW":
                return "Easy";
            case "MEDIUM":
                return "Medium";
            case "HIGH":
                return "Hard";
            default:
                return "";

        }
    }
    const submitTest = () => {
        const attemptQuestionsLength = questionsData.filter((el: any) => el.answer);
        if (attemptQuestionsLength?.length > 0) {
            setAttemptQuestionCount(attemptQuestionsLength?.length);
            setCanShowPopup(true);
        } else {
            setAttemptQuestionCount(0);
            setCanShowPopup(true);
        }

    }
    const onClickCancel = () => {
        setCanShowPopup(false);
    }
    return (
        <div className="row  py-3">
            {Prompt}
            <div
                className={`modal fade ${canShowPopup ? "show" : "false"}`}
                style={{ display: canShowPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog pariksha-model-dialog" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  pariksha-model-info">
                            <div className='pariksha-model-border'>
                                <div className='mt-3'>
                                    <h5 className='blue_text'>You have attempted {attemptQuestionCount} out of {questionsData?.length} questions</h5>
                                </div>
                                <div className='mt-4 mb-3'>
                                    <h5 className='blue_text'>Do you want to submit the {type === 'WORKSHEETS' ? "worksheet" : "test"}?</h5>
                                    <div className='mt-4'>
                                        <button className='btn test-btn' onClick={handleCompleteSubmit}>YES</button>
                                        <button className='btn test-btn' onClick={onClickCancel}>NO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    {type === "TESTS" && <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`}><span>Abhyaas</span></Link></li> /
                        {/* <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{testInfo?.subject_name}</span></Link></li> / */}
                        <li className="breadcrumb-item "><span> {testInfo?.subject_name?.toUpperCase()} Assessment {testInfo?.test_no}</span></li>
                    </ol>}
                    {type === 'WORKSHEETS' && <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item "><Link to="/student-dashboard/list"><span className='pe-2'>Dashboard</span></Link></li> /
                        <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/test-level-status/${moduleUUID}/${userCode}/${schoolCode}`}><span>Abhyaas</span></Link></li> /
                        <li className="breadcrumb-item "><Link to={`/student-dashboard/list/abhyas/progress-view/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{testInfo?.subject_name?.toUpperCase()} Dashboard </span></Link></li> /
                        <li className="breadcrumb-item "><span>Assessment Worksheet</span></li>
                    </ol>}
                </nav>
                {type === 'WORKSHEETS' && <h5 className='text-center mt-2 mb-4 '> Worksheet : {testInfo?.module_code === "PARIKSHA" ? "Assigned by PARIKSHA" : "Assessment Tests"}</h5>}
                {canShowHeader &&
                    <div id='q_timer_block'>
                        <div className=' p-3 time-block d-md-flex justify-content-between align-items-end'>
                            {type === 'TESTS' && <div>
                                <h2 className="m-0"> Test - {testInfo?.test_no < 9 && <span>0</span>}{testInfo?.test_no ? testInfo?.test_no : ''}</h2>
                                <h3 className="m-0"> Time Limit -  {minutes} : {seconds < 9 ? `0${seconds}` : seconds}  mins</h3>
                            </div>}
                            {type === 'WORKSHEETS' && <div>
                                <h2 className="m-0"> {testInfo?.topic_name}</h2>
                                <h3 className="m-0"> {testInfo?.name}</h3>
                                <h3 className="m-0"> Worksheet Level</h3>
                                {worksheets?.map((item: any, i: number) => (
                                    <div key={i} className="circle cursor-pointer" style={{ backgroundColor: getCircleColor(item) }}>
                                        {/* <p>{i + 1}</p>
                                         */}
                                        <p>{getLevelMedium(item?.level)?.charAt(0)}</p>
                                    </div>
                                ))}
                            </div>}
                            {type !== 'WORKSHEETS' && <div>
                                {/* <p className='mt-3 mb-0 m-md-0 f12'>Remaining Time</p> */}
                                <TimerInfo
                                    minutes={minutes}
                                    seconds={seconds}
                                    startTimer={startTimer}
                                    time_remain={(value: any) => {
                                        if (value === '') {
                                            time_remain_storage = "5";
                                        } else {
                                            time_remain_storage = value;
                                            completedTime = time_remain_storage;
                                        }

                                    }}
                                    timeUp={() => {
                                        if (type === "TESTS") {
                                            handleCompleteSubmit();
                                        }
                                        timeUp = true;
                                    }} />
                            </div>}

                        </div>
                    </div>}
                {questionsData?.length > 0 && <div className='row'>

                    <div className='col-12 col-md-8 col-lg-9 mt-3'>
                        <div className='app_qestion_board d-md-none mb-3 mx-1'>
                            <h3 className='text-center f14 text-danger '>Questions Board </h3>
                            <div className={`q_view ${!showQuestions && "hide_q_block"}`}>
                                <div className=' app_question_no_block d-block justify-content-center'>
                                    <div className="question-pattern ms-2 mb-1 ">
                                        {questionsData.map((questionNo: any, i: number) => (
                                            <button key={i} className={`app_q_no cursor-pointer  ${questionNo.id == questionsData[currentQuestion]?.id ? "bg-secondary" : questionNo?.checked ? "q-primary" : "q-danger"}`} onClick={() => selectQuestion(i)}>
                                                {i + 1}
                                            </button>

                                        ))}
                                    </div>
                                </div>

                                <div className='d-flex  mt-1 justify-content-center '>
                                    <div className='d-flex border-top border-secondary border-1 pt-1'>
                                        <div className='app_square-answer'></div>
                                        <div className='app_answer f16 fw-bold'>Answered</div>
                                    </div>
                                    <div className='d-flex ps-2 border-top border-secondary border-1 pt-1'>
                                        <div className='app_square-not-answer'></div>
                                        <div className='app_not-answer f16 fw-bold'>Not Answered</div>
                                    </div>
                                </div>
                            </div>
                            <p className='m-0  app_queston_dwn' onClick={() => setShowQuestions(!showQuestions)} style={{ top: showQuestions ? "99%" : "77%" }}>
                                {!showQuestions ? <i className="fa  ps-2">&#xf103;</i>
                                    : <i className="fa  ps-2">&#xf102;</i>}
                            </p>
                        </div>
                        <div className='q_display_block'>
                            <MathJaxContext>
                                <MathJax>
                                    <div className='img_ck'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='d-flex'>
                                                    <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                        Q{currentQuestion + 1}:
                                                    </p>
                                                    <div className='ms-lg-4 ps-3'>
                                                        <div className='d-flex'>
                                                            {questionsData[currentQuestion]?.video_url && <video ref={videoRef} src={questionsData[currentQuestion]?.video_url} className='w-25 me-3 rounded-3' controls controlsList="nodownload"></video>}
                                                            {questionsData[currentQuestion].audio_url && <audio ref={audioRef} src={questionsData[currentQuestion]?.audio_url} controls controlsList="nodownload"></audio>}
                                                        </div>
                                                        <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                            <div className={`${imageSize > 190 ? "img_ck_lg" : "img_ck"}`} dangerouslySetInnerHTML={onViewQuestion(questionsData[currentQuestion]?.question)}></div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <form>
                                            {questionsData[currentQuestion]?.options?.map((options: any, j: number) => (
                                                <div className='d-flex pt-3 ms-2' key={j}>
                                                    <div className=''>
                                                        <span>
                                                            <input className={`mb-0 cursor-pointer ${getDomElements(options?.value, "img") && "mt-5"}`} type="radio" name="option" checked={questionsData[currentQuestion]?.answer === alphabets[j] ? true : false} onChange={(e) => { handleQuestionOptions(options, currentQuestion, j) }}
                                                                onKeyDown={(e) => handleKeyDown(e, currentQuestion)}
                                                            // value={question.answer = question?.correct_answer}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className='ms-5 ps-2'>
                                                        <span className='img_ck_otp ' dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                    </div>
                                                </div>
                                            ))}
                                        </form>
                                    </div>
                                </MathJax>
                            </MathJaxContext>
                        </div>
                        <div className='d-none d-md-flex justify-content-center mt-3'>
                            <button className={` me-2 btn-test ${currentQuestion === 0 && "bg-white text-secondary"}`} onClick={handlePrevious} disabled={currentQuestion === 0} >
                                Back
                            </button>
                            <button className=' me-2 btn-test ' onClick={handleClearSelection}>Clear Selection</button>
                            <button className={` btn-test ${currentQuestion === questionsData.length - 1 && "bg-white text-secondary"}`} onClick={handleNext} disabled={currentQuestion === questionsData.length - 1}>
                                Save and Next
                            </button>

                        </div>
                        <div className='mt-1 d-flex justify-content-around  mx-2 d-md-none'>
                            <button className={`f12 btn  ${currentQuestion === questionsData.length - 1 ? "btn-light" : "btn-primary"}  py-1 px-3 px-md-4" type='button`} onClick={handleNext} disabled={currentQuestion === questionsData.length - 1}>Save and Next</button>
                            <button className={`f12 btn  py-1 px-3  ms-2 ms-md-0 ${currentQuestion === 0 ? "btn-light text-dark" : "btn-warning text-white"}`} type='button' onClick={handlePrevious} disabled={currentQuestion === 0}>Back</button>
                            <button className="f12 btn btn-secondary py-1 px-3 ms-2" type='button' onClick={handleClearSelection}>Clear Selection</button>
                            <button className={`f12 btn btn-success ${type === 'TESTS' ? " py-2" : "py-1"} px-3 ms-2`} type='button' onClick={submitTest}>Submit {type === 'TESTS' ? "Test" : <p className='m-0'>Worksheet</p>}</button>
                        </div>
                    </div>
                    <div className='col-12 col-md-4 col-lg-3 d-none d-md-block'>
                        <div className='ms-3'>
                            <div className='q_board mt-3 justify-content-center'>
                                <h3 className='text-danger f18 text-center mt-1 pb-2 border-bottom'>Total Questions</h3>
                                <div className='question_no_block border-bottom'>
                                    <div className="question-pattern  py-2 ">
                                        {questionsData.map((questionNo: any, i: number) => (
                                            <span key={i} className={`number cursor-pointer  ${questionNo.id == questionsData[currentQuestion]?.id ? "bg-secondary" : questionNo?.checked ? "q-primary" : "q-danger"}`} onClick={() => selectQuestion(i)}>
                                                {i + 1}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-around mt-1 flex-wrap'>
                                    <div className='d-flex'>
                                        <div className='square-answer'></div>
                                        <div className='answer f14 fw-bold'>Answered</div>
                                    </div>
                                    <div className='d-flex '>
                                        <div className='square-not-answer'></div>
                                        <div className='not-answer f14 fw-bold'>Not Answered</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {questionsData.length > 0 &&
                            <div className="py-2 text-center mt-1 me-3 me-lg-0 ms-lg-3" style={{ width: "218px" }}>
                                <button type='button' className="btn submit-btn-test mb-3 px-3 py-2 cursor-pointer" onClick={submitTest}>Submit Test</button>
                            </div>
                        }
                    </div>
                </div>}

            </div>}
        </div >
    )
}
