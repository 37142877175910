import React, { useEffect, useRef, useState } from "react";
import FormBuilder from "../../../../components/form-builder";
import { GET_ABHYAS_QUESTION_LIST } from "../../../../app/service/abhyas-question.service";
import { toast } from "react-toastify";
import { GET_BOARD_FILTER_LIST, GET_CHAPTER_FILTER_LIST, GET_MEDIUM_FILTER_LIST, GET_STANDARD_FILTER_LIST, GET_SUBJECT_FILTER_LIST } from "../../../../app/service/common-module.service";
import moment from "moment";
import { ContentAddAbhyaasWorksheet } from "./add-worksheet";
import { ContentWorksheetAddQuestion } from "./add-questions";
import { GET_TOPIC_FITERS_LIST } from "../../../../app/service/abhyas-worksheet-filter.service";


export const ContentWorksheetList = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [standardLoading, setStandardLoading] = useState<boolean>(false);
    const [subjectLoading, setSubjectLoading] = useState<boolean>(false);
    const [chapterLoading, setChapterLoading] = useState<boolean>(false);
    const [questionsLoading, setQuestionsLoading] = useState<boolean>(false);
    const [boardsList, setBoardsList] = useState<any>([]);
    const [mediumList, setMediumList] = useState<any>([]);
    const [standardList, setStandardList] = useState<any>([]);
    const [subjectList, setSubjectList] = useState<any>([]);
    const [chapterList, setChapterList] = useState<any>([]);
    const [testList, setTestList] = useState<any>([]);
    const [topicList, setTopicList] = useState<any>([]);
    const [filterData, setFilterData] = useState<any>({
        board_uuid: '',
        medium_uuid: '',
        standard_uuid: '',
        subject_uuid: '',
        chapter_uuid: '',
        topic_uuid: ''
    });
    const filterForm = useRef<any>({});
    const [canShowTestQuestions, setCanShowTestQuestions] = useState<boolean>(false);
    const [testInfo, setTestInfo] = useState<any>({});
    const [canSelectTest, setCanSelectTest] = useState<boolean>(false);
    const [canShowWorksheet, setCanShowWorksheet] = useState<boolean>(false);

    useEffect(() => {
        getBoardData();
    }, [])
    const getBoardData = () => {
        setLoading(true);
        GET_BOARD_FILTER_LIST().then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    const data = res['rows'];
                    setBoardsList(data);
                    setLoading(false);
                }
            }
        });
    }


    const handleInput = (data: any) => {
        let preData = { ...data.value }
        setFilterData(preData);
        setCanShowWorksheet(true);
        setTimeout(() => {
            setCanShowWorksheet(false);
        }, 200);
    };
    const onChangeBoard = (e: any) => {
        setCanShowTestQuestions(true);
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTestList([]);
        setCanShowTestQuestions(false);
        if (e) {
            getmediumData(e);
        }
        const boardName = boardsList?.find((item: any) => item.uuid === e);
        const data = testInfo;
        data.board_uuid = e;
        data.board_name = boardName?.name;
        data.medium_uuid = '';
        data.standard_uuid = '';
        data.subject_uuid = '';
        data.chapter_uuid = '';
        data.topic_uuid = '';
        setTestInfo({ ...data });

    }

    const getmediumData = (id: any) => {
        GET_MEDIUM_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);

                } else {
                    const data = res['rows'];
                    setMediumList(data);

                }
            }
        });

    }

    const onChangeMedium = (e: any) => {
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTestList([]);
        if (e) {
            getStandardData(e);
        }
        const mediumName = mediumList?.find((item: any) => item.uuid === e);
        const data = testInfo;
        data.medium_uuid = e;
        data.medium_name = mediumName?.name;
        data.standard_uuid = '';
        data.subject_uuid = '';
        data.chapter_uuid = '';
        data.topic_uuid = '';
        setTestInfo({ ...data });
    }

    const getStandardData = (id: any) => {
        GET_STANDARD_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setStandardLoading(false);
                } else {
                    const data = res['rows'];
                    setStandardList(data);
                    setStandardLoading(false);
                }
            }
        });
    }

    const onChangeStandard = (e: any) => {
        setSubjectList([]);
        setChapterList([]);
        setTestList([]);
        const standardName = standardList?.find((item: any) => item.uuid === e);
        const data = testInfo;
        data.standard_uuid = e;
        data.standard_name = standardName?.standard;
        data.subject_uuid = '';
        data.chapter_uuid = '';
        data.topic_uuid = '';
        setTestInfo({ ...data });
        if (e) {
            setSubjectLoading(true);
            getSubjectsData(e);
        } else {
        }
    }

    const getSubjectsData = (id: any) => {
        GET_SUBJECT_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setSubjectLoading(false);
                } else {
                    const data = res['rows'];
                    setSubjectList(data);
                    setSubjectLoading(false);
                }
            }
        });
    }

    const onChangeSubject = (e: any) => {
        setChapterList([]);
        setTopicList([]);
        if (e) {
            getChaptersData(e);
        }
        const subjectName = subjectList?.find((item: any) => item.uuid === e);
        const data = testInfo;
        data.subject_uuid = e;
        data.subject_name = subjectName?.name;
        data.chapter_uuid = '';
        data.topic_uuid = '';
        setTestInfo({ ...data });
    }

    const getChaptersData = (id: any) => {
        GET_CHAPTER_FILTER_LIST(id).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setChapterLoading(false);

                } else {
                    const data = res['rows'];
                    setChapterList(data);
                    setChapterLoading(false);
                }
            }
        });
    }

    const onChangeChapter = (e: any) => {
        setTopicList([]);
        const chapterName = chapterList?.find((item: any) => item.uuid === e);
        const data = testInfo;
        data.chapter_uuid = e;
        data.chapter_name = chapterName?.name;
        data.topic_uuid = '';
        setTestInfo({ ...data });
        if (e) {
            if (e) {
                GET_TOPIC_FITERS_LIST(filterData?.board_uuid, filterData?.medium_uuid, filterData?.standard_uuid, filterData?.subject_uuid, e).then((res: any) => {
                    if (res) {
                        if (res.status === 'fail') {
                            toast.error(res?.err);

                        } else {
                            const data = res['rows'];
                            setTopicList(data);
                        }
                    }
                });
            }
        } else {
        }
    }

    // const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault();

        }
    };


    const onChangeTest = (e: any) => {
        filterData.uuid = e;
        setCanSelectTest(true);
        if (e) {
            setQuestionsLoading(true);
            setLoading(true);
            GET_ABHYAS_QUESTION_LIST(e, {}).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res?.err);
                        setQuestionsLoading(false);
                        setLoading(false);
                    } else {
                        const data = res.rows;
                        if (data.length > 0) {
                            data?.forEach((element: any, i: number) => {
                                element.created_dt = moment(element?.created_dt?.split("T")[0]).format('DD-MM-yyyy');
                                if (element?.modified_dt) {
                                    element.modified_dt = moment(element?.modified_dt?.split("T")[0]).format('DD-MM-yyyy');
                                }
                                element.data = JSON.parse(element?.data)
                            });
                            setQuestionsLoading(false);
                            getmediumData(data[0].board_uuid);
                            getStandardData(data[0].medium_uuid);
                            getSubjectsData(data[0].standard_uuid);
                            getChaptersData(data[0].subject_uuid);
                            setTimeout(() => {
                                setLoading(false);
                                setCanShowTestQuestions(true);
                            }, 1000);

                        }


                    }
                }
            });
        }
    }

    const onChangeTopic = (e: any) => {
        const topicName = topicList?.find((item: any) => item.uuid === e);
        const data = testInfo;
        data.topic_uuid = e;
        data.topic_name = topicName?.name;
        setTestInfo({ ...data });
    }


    const handleRefresh = () => {
        setMediumList([]);
        setStandardList([]);
        setSubjectList([]);
        setChapterList([]);
        setTestList([]);
        const filterData = {};
        setFilterData({ ...filterData });
        setCanShowWorksheet(true);
        const data = {};
        setTestInfo({ ...data });
        filterForm.current.reset();
    }

    const onChangeAddUpdateTest = (data: any) => {
        // filterForm.current.reset();
        setLoading(false);
        if (data) {
            setTestInfo(data);
            getmediumData(data.board_uuid);
            getStandardData(data.medium_uuid);
            getSubjectsData(data.standard_uuid);
            getChaptersData(data.subject_uuid);
        }
    }

    const onClickBackTest = (data: any) => {
        setCanShowTestQuestions(false);
        setFilterData({});
        setTestInfo({});
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        setCanSelectTest(false);
    }
    const handleBackQuestion = () => {
        setCanSelectTest(false);
        setCanShowTestQuestions(false);
    }
    const validationFilters = () => {
        if (testInfo?.board_uuid && testInfo?.medium_uuid && testInfo?.standard_uuid && testInfo?.subject_uuid && testInfo?.chapter_uuid && testInfo?.topic_uuid) {
            return false;
        } else {
            return true;
        }
    }

    const getDisabledFilters = (data: any) => {
        if (canSelectTest) {
            return true;
        } else {
            return false;
        }
    }
    return (
        <div className="row  border-primary py-3" >
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className='my-2'>
                    {!canShowTestQuestions && <div>
                        <FormBuilder onUpdate={handleInput}>
                            <form ref={filterForm}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className='mb-4'>
                                            <label>Board</label>
                                            <select name="board_uuid" id="board_uuid" className="form-select ft-14" disabled={getDisabledFilters(testInfo)} value={testInfo?.board_uuid} onChange={(e) => { onChangeBoard(e.target.value) }}>
                                                <option value="">Select Board</option>
                                                {boardsList?.map((name: any, i: number) => (
                                                    <option key={i} value={name?.uuid}>
                                                        {name?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='mb-4 position-relative'>
                                            <label>Medium</label>
                                            <select name="medium_uuid" id="medium_uuid" className="form-select ft-14" disabled={getDisabledFilters(testInfo)} value={testInfo?.medium_uuid} onChange={(e) => { onChangeMedium(e.target.value) }}>
                                                <option value="">Select Medium</option>
                                                {mediumList?.map((name: any, i: number) => (
                                                    <option key={i} value={name?.uuid}>
                                                        {name?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='mb-4  position-relative'>
                                            <label>Standard</label>
                                            <select name="standard_uuid" id="standard_uuid" className="form-select ft-14" disabled={getDisabledFilters(testInfo)} value={testInfo?.standard_uuid} onChange={(e) => { onChangeStandard(e.target.value) }}>
                                                <option value="">Select Standard</option>
                                                {standardList?.map((name: any, i: number) => (
                                                    <option key={i} value={name?.uuid}>
                                                        {name?.standard}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='mb-4  position-relative'>
                                            <label>Subject</label>
                                            <select name="subject_uuid" id="subject_uuid" className="form-select ft-14" disabled={getDisabledFilters(testInfo)} value={testInfo?.subject_uuid} onChange={(e) => { onChangeSubject(e.target.value) }}>
                                                <option value="">Select Subject</option>
                                                {subjectList?.map((name: any, i: number) => (
                                                    <option key={i} value={name?.uuid}>
                                                        {name?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='mb-4  position-relative'>
                                            <label>Chapter</label>
                                            <select name="chapter_uuid" id="chapter_uuid" className="form-select ft-14" disabled={getDisabledFilters(testInfo)} value={testInfo?.chapter_uuid} onChange={(e) => { onChangeChapter(e.target.value) }}>
                                                <option value="">Select Chapter</option>
                                                {chapterList?.map((name: any, i: number) => (
                                                    <option key={i} value={name?.uuid}>
                                                        {name?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className='mb-4  position-relative'>
                                            <label>Topic</label>
                                            <select name="topic_uuid" id="topic_uuid" className="form-select ft-14 " value={testInfo?.topic_uuid} onChange={(e) => { onChangeTopic(e.target.value) }}>
                                                <option value="">Select Topic</option>
                                                {topicList?.map((name: any, i: number) => (
                                                    <option key={i} value={name?.uuid}>
                                                        {name?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </FormBuilder>
                        <div className="position-relative text-end me-2">
                            <button className="btn clear-btn cursor-pointer py-1 position-absolute" type="button" style={{ bottom: "22px", right: "0px" }} onClick={() => handleRefresh()}>
                                Clear
                            </button>
                        </div>
                    </div>
                    }
                    {/* // Question // */}

                    {(!canShowWorksheet && !canShowTestQuestions && !validationFilters()) &&
                        <ContentAddAbhyaasWorksheet isLinked={"false"} filterData={testInfo} updateList={onChangeAddUpdateTest} selectTest={onChangeTest} />
                    }
                    {canShowTestQuestions &&
                        <ContentWorksheetAddQuestion test_uuid={filterData?.uuid} setBackQuestionsList={handleBackQuestion} />
                    }
                </div>
            }

        </div>
    )
}